<template lang="pug">
.my_team(v-if="$store.state.user.accessToken && isTeamExist")
  .user_info.py-7.d-flex.justify-start.justify-md-space-between.flex-column.flex-md-row
    .user_basis.d-flex.align-center
      .user.d-flex.flex-grow-1.align-center
        img.user_avater.avater_lg.d-none.d-md-block(
          v-if="!$store.state.user.accessToken || ($store.state.user.accessToken && !$store.state.user.photo)",
          :src="gcAvatar"
        )
        img.user_avater.avater_lg.d-none.d-md-block(
          v-if="$store.state.user.accessToken && $store.state.user.photo",
          :src="$store.state.user.photo"
        )
        //- .user_desc_wrapper.ml-6.flex-grow-1.align-items-center
        span.users_team.ml-0.ml-md-6.flex-grow-1
          span.font-weight-bold.player {{ $store.state.user.nickname }}
          span.font-weight-bold.ml-1.mr-1 的球隊
          svg-icon.ml-1(
            iconClass="mobile_ok",
            v-if="$store.state.user.isPhoneVerified"
          )

          svg-icon.ml-1(
            iconClass="profile_ok",
            v-if="$store.state.user.isProfileComplete"
          )
          //- .level.text-overline vagetable chicken
        .user_data_title.text-overline.d-md-none(
          style="font-size: 0.68rem !important; color: #8e8e8e"
        )
          font-awesome-icon.mr-1(:icon="['fas', 'flag']")
          span 參與場數
          span.data_value.font-weight-bold.ml-1 {{ myTeamInfo.game_count }}
    .user_advanced.d-flex.flex-row-reverse.flex-md-row
      .achievement_and_wallet_container.ml-0.mx-md-3
        .rect.pb-0.pb-md-2
          .achievement.px-1.py-1.py-md-4(@click="showAchievementDialog")
            .content.d-flex.align-center
              svg-icon(
                iconClass="gc_achievement_icon",
                className="gc_achievement_icon"
              )
              .titles.d-flex.flex-column.justify-space-between
                span.text-body.text_gray.d-none.d-md-block ACHIEVEMENT
                span.text-body 我的成就
              v-spacer
        .rect.pt-0.pt-md-2
          .wallet.py-1.py-md-4(@click="toMemberCenter")
            .content.d-flex.align-center
              svg-icon(iconClass="gc_coin_icon", className="gc_coin_icon")
              .titles.d-flex.flex-column.justify-space-between
                span.text-body.text_gray.d-none.d-md-block FANTASY COINS
                h2.text-body {{ toThousands(userInfo.fantasy_coin) }}
              v-spacer

      .user_data.radius_s.py-2.px-7
        li.d-flex.justify-space-between
          .user_data_title.text-overline(style="color: #8eb0d6")
            font-awesome-icon.mr-1.mr-md-2(:icon="['fas', 'trophy']")
            | 排名
          span.data_value {{ myTeamInfo.ranking }}
        li.d-flex.justify-space-between
          .user_data_title.text-overline(style="color: #6a72d6")
            font-awesome-icon.mr-1.mr-md-2(:icon="['fas', 'star']")
            | 總積分
          span.data_value {{ myTeamInfo.score }}
        li.d-flex.justify-space-between
          .user_data_title.text-overline(style="color: #b88ed6")
            font-awesome-icon.mr-1.mr-md-2(:icon="['fas', 'handshake']")
            | 本土剩餘交易次數
          span.data_value {{ seasonData.is_transaction_unlimited ? '無限' : myTeamInfo.local_player_transaction_count_left / 2 }}
        li.d-flex.justify-space-between
          .user_data_title.text-overline(style="color: #b88ed6")
            font-awesome-icon.mr-1.mr-md-2(:icon="['fas', 'handshake']")
            | 洋將剩餘交易次數
          span.data_value {{ seasonData.is_transaction_unlimited ? '無限' : myTeamInfo.foreign_player_transaction_count_left / 2 }}
        li.d-flex.justify-space-between.d-none.d-md-flex
          .user_data_title.text-overline(style="color: #8e8e8e")
            font-awesome-icon.mr-1.mr-md-2(:icon="['fas', 'flag']")
            | 參與場數
          span.data_value {{ myTeamInfo.game_count }}
  .team_stats.radius_l.bg_white.position-relative(
    :style="{ top: $store.state.navbarHeight > 48 ? '0px' : $store.state.navbarHeight + 'px' }"
  )
    .team_stats_header.d-flex.flex-column.flex-md-row
      .team_states_menu.d-flex.align-center
        .team_states_tabs
          v-tabs.position-relative(v-model="tab", ripple="false")
            v-tabs-slider(color="#6c7a8e")
            v-tab(:ripple="false") 
              | Stats
            v-tab(:ripple="false") 
              | Average Stats
            v-tab(:ripple="false") 
              | Opponents
      .date.d-flex.align-center
        .date_picker.d-flex.align-center.justify-space-around
          svg-icon(
            @click="toPrevDate",
            iconClass="arrow_left_round_rect",
            className="arrow_left_round_rect",
            :class="{ arrow_disable: getDateArrowState('PREV') }"
          )
          span(@click="openDateDialog") {{ date }}
          svg-icon(
            @click="toNextDate",
            iconClass="arrow_right_round_rect",
            className="arrow_right_round_rect",
            :class="{ arrow_disable: getDateArrowState('NEXT') }"
          ) 
        v-spacer.d-block.d-md-none
        .d-md-none
          v-tooltip(v-model="taxAlertShow", bottom="", v-if="tooltipShow")
            template(v-slot:activator="{ on, attrs }")
              .tax_alert.mr-2
                font-awesome-icon(
                  :icon="['fas', 'exclamation-triangle']",
                  v-bind="attrs",
                  v-on="on"
                )
            span(v-bind="attrs", v-on="on") {{ statHasGameText == 'NEW' ? '已超過隊伍價值門檻，如不調整將會扣取對應分數' : '此場比賽隊伍價值超過上限，已被扣取對應分數' }}
        .game_status.d-block.d-md-none(
          :class="[{ ready: statHasGameText == 'NEW' }, { going: statHasGameText == 'LOCK' }, { ending: statHasGameText == 'END' }]"
        ) {{ getGameStatus() }}
    .team_stats.wrapper.pb-10(v-if="tab == 0")
      .team_stats_body.pa-0.py-md-5.px-md-9(ref="table")
        v-row.d-none.d-md-flex
          v-col.pa-1.pa-md-3(cols="auto", md="3", lg="3")
            .btn.btn_water_blue(v-if="statHasGame", @click="autoDailyRoster") 自動登錄球員
          v-spacer
          v-col.pa-1.pa-md-3.offset-md-0.offset-lg-3(
            cols="auto",
            md="3",
            lg="2"
          )
            .btn.btn_pink_hollow(@click="showTableForeignDialog") 洋將交易
          v-col.pa-1.pa-md-3(cols="auto", md="3", lg="2")
            .btn.btn_purple_hollow(@click="showTableLocalDialog") 本土交易
        v-row.px-1.px-sm-4.d-none.d-md-flex(
          justify="space-between",
          v-if="statHasGame"
        )
          v-col.title.pl-0.draggableTableTitle(
            ref="draggableTableTitle",
            cols="auto"
          )
            h3 球員列表
          v-col.pr-0.d-flex.align-center(cols="auto")
            .tax_alert.mr-3(v-if="tooltipShow")
              font-awesome-icon(:icon="['fas', 'exclamation-triangle']")
              span.ml-1 已超過隊伍價值門檻，如不調整將會扣取對應分數
            .game_status(
              :class="[{ ready: statHasGameText == 'NEW' }, { going: statHasGameText == 'LOCK' }, { ending: statHasGameText == 'END' }]"
            ) {{ getGameStatus() }}
        v-row.draggable_table.desktop.table_row.mt-0(v-if="statHasGame")
          v-col.table.f_table.local.table_col(cols="12")
            table
              thead
                tr.top_head
                  sticky-column.bdl.bdr(
                    colspan="2",
                    style="width: 130px; min-width: 130px",
                    :offset="0"
                  )
                  th.bdr(colspan="2") 
                  th.bdr(colspan="3") 價值
                  th.bdr(colspan="2") 
                  th.bdr(colspan="3") 投籃
                  th.bdr(colspan="3") 三分
                  th.bdr(colspan="3") 罰球
                  th.bdr(colspan="3") 籃板
                  th.bdr(colspan="5") 
                tr.bottom_head
                  sticky-column.bdl(
                    style="width: 60px; min-width: 60px",
                    :offset="0"
                  ) 位置
                  sticky-column.bdr2(
                    style="width: 70px; min-width: 70px",
                    :offset="60"
                  ) 球員
                  th.bdr2 隊伍
                  th.bdr 對手
                  th 季前
                  th 目前
                  th.bdr 趨勢
                  th.bdr 出賽
                  th.bdr 時間
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 進攻
                  th 防守
                  th.bdr 籃板
                  th 助攻
                  th 抄截
                  th 火鍋
                  th 失誤
                  th.bdr 得分
              tbody
                tr(
                  @click="playerClick(player, index)",
                  v-for="(player, index) in statHasGamePlayerList",
                  :key="index",
                  :class="[{ highlight_team: player.arranged_position && player.arranged_position != 'Empty' }, { foreign: player.arranged_position == 'FOREIGN' }, { change_selected: player.view_state == 'S' }, { change_enable: player.view_state == 'Y' }, { change_disable: player.view_state == 'N' }]"
                )
                  sticky-column.bdr.bdl(
                    style="width: 60px; min-width: 60px",
                    :offset="0"
                  ) 
                    .position {{ getArrangedPosition(player.arranged_position) }}
                  sticky-column.bdr2.player_info(
                    style="width: 70px; min-width: 70px",
                    :offset="60"
                  ) 
                    p {{ player.name ? player.name : ' ' }}
                      span.pos {{ player.position }}
                  td.bdr {{ player.team_name }}
                  td.bdr.opponent {{ player.opponent_team_name }}
                  td {{ player.preseason_value }}
                  td {{ player.current_value }}
                  td.bdr(
                    :class="[(((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) > 0 ? 'text_secondary' : 'text_light_gray']"
                  ) {{ player.preseason_value ? (((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) + '%' : '' }}
                  td.bdr {{ player.record_matches }}
                  td.bdr {{ player.box.secs >= 0 ? getMinutes(player.box.secs) : '-' }}
                  td {{ player.box.fg_m != undefined ? player.box.fgm : '-' }}
                  td {{ player.box.fg_a != undefined ? player.box.fga : '-' }}
                  td.bdr {{ player.box.fg_percent != undefined ? (player.box.fg_percent * 100).toFixed(1) + '%' : '-' }}
                  td {{ player.box.three_pt_fgm != undefined ? player.box.three_pt_fgm : '-' }}
                  td {{ player.box.three_pt_fga != undefined ? player.box.three_pt_fga : '-' }}
                  td.bdr {{ player.box.three_pts_fg_percent != undefined ? (player.box.three_pt_fg_percent * 100).toFixed(1) + '%' : '-' }}
                  td {{ player.box.ftm != undefined ? player.box.ftm : '-' }}
                  td {{ player.box.fta != undefined ? player.box.fta : '-' }}
                  td.bdr {{ player.box.ft_percent != undefined ? (player.box.ft_percent * 100).toFixed(1) + '%' : '-' }}
                  td {{ player.box.off_reb != undefined ? player.box.off_reb : '-' }}
                  td {{ player.box.def_reb != undefined ? player.box.def_reb : '-' }}
                  td.bdr {{ player.box.reb != undefined ? player.box.reb : '-' }}
                  td {{ player.box.ast != undefined ? player.box.ast : '-' }}
                  td {{ player.box.stl != undefined ? player.box.stl : '-' }}
                  td {{ player.box.blk != undefined ? player.box.blk : '-' }}
                  td {{ player.box.tov != undefined ? player.box.tov : '-' }}
                  td {{ player.box.pts != undefined ? player.box.pts : '-' }}

        v-row.draggable_table.mobile.sticky.table_row.mt-0(
          :style="{ height: 'calc(' + $store.state.screenHeight + 'px - 254px)' }",
          v-if="statHasGame"
        )
          v-col.table.f_table.local.table_col(
            cols="12",
            :style="{ maxHeight: 'calc(' + $store.state.screenHeight + 'px - 254px)' }"
          )
            table
              thead
                tr.top_head
                  th.bdl(style="min-width: 45px")
                  th.bdr(colspan="3") 價值
                  th.bdr(colspan="2") 
                  th.bdr(colspan="3") 投籃
                  th.bdr(colspan="3") 三分
                  th.bdr(colspan="3") 罰球
                  th.bdr(colspan="3") 籃板
                  th.bdr(colspan="5") 
                tr.bottom_head
                  th
                  th 季前
                  th 目前
                  th.bdr 趨勢
                  th.bdr 出賽
                  th.bdr 時間
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 進攻
                  th 防守
                  th.bdr 籃板
                  th 助攻
                  th 抄截
                  th 火鍋
                  th 失誤
                  th.bdr 得分
              tbody
                template(v-for="(player, index) in statHasGamePlayerList")
                  tr(
                    @click="playerClick(player, index)",
                    :class="[{ highlight_team: player.arranged_position && player.arranged_position != 'Empty' }, { foreign: player.arranged_position == 'FOREIGN' }, { change_selected: player.view_state == 'S' }, { change_enable: player.view_state == 'Y' }, { change_disable: player.view_state == 'N' }]"
                  )
                    sticky-column.bdl(
                      colspan="7",
                      style="width: 100px; min-width: 100px",
                      :offset="0"
                    ) 
                      .player_container
                        .position {{ getArrangedPosition(player.arranged_position) }}
                        .player_info
                          p.player {{ player.name }}
                            span.pos {{ player.position }}
                          p.team {{ player.team_name }}
                            span.vs {{  player.opponent_team_name == null || player.opponent_team_name == ""? "" : 'vs'  }}
                            span.opponent {{ player.opponent_team_name }}
                    td(colspan="16")

                  tr(
                    @click="playerClick(player, index)",
                    :class="[{ highlight_team: player.arranged_position && player.arranged_position != 'Empty' }, { foreign: player.arranged_position == 'FOREIGN' }, { change_selected: player.view_state == 'S' }, { change_enable: player.view_state == 'Y' }, { change_disable: player.view_state == 'N' }]"
                  )
                    td
                    td {{ player.preseason_value }}
                    td {{ player.current_value }}
                    td.bdr(
                      :class="[(((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) > 0 ? 'text_secondary' : 'text_light_gray']"
                    ) {{ player.preseason_value ? (((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) + '%' : '' }}
                    td.bdr {{ player.record_matches }}
                    td.bdr {{ player.box.secs >= 0 ? getMinutes(player.box.secs) : '-' }}
                    td {{ player.box.fgm != undefined ? player.box.fgm : '-' }}
                    td {{ player.box.fga != undefined ? player.box.fga : '-' }}
                    td.bdr {{ player.box.fg_percent != undefined ? (player.box.fg_percent * 100).toFixed(1) + '%' : '-' }}
                    td {{ player.box.three_pt_fgm != undefined ? player.box.three_pt_fgm : '-' }}
                    td {{ player.box.three_pt_fga != undefined ? player.box.three_pt_fga : '-' }}
                    td.bdr {{ player.box.three_pt_fg_percent != undefined ? (player.box.three_pt_fg_percent * 100).toFixed(1) + '%' : '-' }}
                    td {{ player.box.ftm != undefined ? player.box.ftm : '-' }}
                    td {{ player.box.fta != undefined ? player.box.fta : '-' }}
                    td.bdr {{ player.box.ft_percent != undefined ? (player.box.ft_percent * 100).toFixed(1) + '%' : '-' }}
                    td {{ player.box.off_reb != undefined ? player.box.off_reb : '-' }}
                    td {{ player.box.def_reb != undefined ? player.box.def_reb : '-' }}
                    td.bdr {{ player.box.reb != undefined ? player.box.reb : '-' }}
                    td {{ player.box.ast != undefined ? player.box.ast : '-' }}
                    td {{ player.box.stl != undefined ? player.box.stl : '-' }}
                    td {{ player.box.blk != undefined ? player.box.blk : '-' }}
                    td {{ player.box.tov != undefined ? player.box.tov : '-' }}
                    td {{ player.box.pts != undefined ? player.box.pts : '-' }}
        v-row.px-1.px-sm-4.d-none.d-md-flex(
          justify="space-between",
          v-if="!statHasGame"
        )
          v-col.title.pl-0(cols="auto")
            h3 球員列表
          v-col.d-flex.align-center.pr-0(cols="auto")
            .tax_alert.mr-3(v-if="tooltipShow")
              font-awesome-icon(:icon="['fas', 'exclamation-triangle']")
              span.ml-1 此場比賽隊伍價值超過上限，已被扣取對應分數
            .game_status(
              :class="[{ ready: statHasGameText == 'NEW' }, { going: statHasGameText == 'LOCK' }, { ending: statHasGameText == 'END' }]"
            ) {{ getGameStatus() }}
        v-row.undraggable_table.desktop.table_row.mt-0(v-if="!statHasGame")
          v-col.table.f_table.local.table_col(cols="12")
            table
              thead
                tr.top_head
                  sticky-column.bdl.bdr(
                    colspan="2",
                    style="width: 130px; min-width: 130px",
                    :offset="0"
                  )
                  th.bdr(colspan="2") 
                  th.bdr(colspan="3") 價值
                  th.bdr(colspan="2") 
                  th.bdr(colspan="3") 投籃
                  th.bdr(colspan="3") 三分
                  th.bdr(colspan="3") 罰球
                  th.bdr(colspan="3") 籃板
                  th.bdr(colspan="5") 
                tr.bottom_head
                  sticky-column.bdl(
                    style="width: 60px; min-width: 60px",
                    :offset="0"
                  ) 位置
                  sticky-column.bdr2(
                    style="width: 70px; min-width: 70px",
                    :offset="60"
                  ) 球員
                  th.bdr 隊伍
                  th.bdr 對手
                  th 季前
                  th 目前
                  th.bdr 趨勢
                  th.bdr 出賽
                  th.bdr 時間
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 進攻
                  th 防守
                  th.bdr 籃板
                  th 助攻
                  th 抄截
                  th 火鍋
                  th 失誤
                  th 得分
              tbody
                tr(
                  v-for="(player, index) in statCurrentPlayerList",
                  :key="index",
                  :class="{ highlight_team: player.is_calculated }"
                )
                  sticky-column.bdr.bdl(
                    style="width: 60px; min-width: 60px",
                    :offset="0"
                  )
                    .position {{ getArrangedPosition(player.arranged_position) }}
                  sticky-column.bdr2.player_info(
                    style="width: 70px; min-width: 70px",
                    :offset="60"
                  ) 
                    p {{ player.name != undefined ? player.name : '' }}
                      span.pos {{ player.position }}
                  td.bdr {{ player.team_name }}
                  td.bdr.opponent {{ player.opponent_team_name }}
                  td {{ player.preseason_value }}
                  td {{ player.current_value }}
                  td.bdr(
                    :class="[(((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) > 0 ? 'text_secondary' : 'text_light_gray']"
                  ) {{ player.preseason_value ? (((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) + '%' : '' }}
                  td.bdr {{ player.record_matches }}
                  td.bdr {{ player.box.secs >= 0 ? getMinutes(player.box.secs) : '-' }}
                  td {{ player.box.fgm != undefined ? player.box.fgm : '-' }}
                  td {{ player.box.fga != undefined ? player.box.fga : '-' }}
                  td.bdr {{ player.box.fg_percent != undefined ? (player.box.fg_percent * 100).toFixed(1) + '%' : '-' }}
                  td {{ player.box.three_pt_fgm != undefined ? player.box.three_pt_fgm : '-' }}
                  td {{ player.box.three_pt_fga != undefined ? player.box.three_pt_fga : '-' }}
                  td.bdr {{ player.box.three_pt_fg_percent != undefined ? (player.box.three_pt_fg_percent * 100).toFixed(1) + '%' : '-' }}
                  td {{ player.box.ftm != undefined ? player.box.ftm : '-' }}
                  td {{ player.box.fta != undefined ? player.box.fta : '-' }}
                  td.bdr {{ player.box.ft_percent != undefined ? (player.box.ft_percent * 100).toFixed(1) + '%' : '-' }}
                  td {{ player.box.off_reb != undefined ? player.box.off_reb : '-' }}
                  td {{ player.box.def_reb != undefined ? player.box.def_reb : '-' }}
                  td.bdr {{ player.box.reb != undefined ? player.box.reb : '-' }}
                  td {{ player.box.ast != undefined ? player.box.ast : '-' }}
                  td {{ player.box.stl != undefined ? player.box.stl : '-' }}
                  td {{ player.box.blk != undefined ? player.box.blk : '-' }}
                  td {{ player.box.tov != undefined ? player.box.tov : '-' }}
                  td {{ player.box.pts != undefined ? player.box.pts : '-' }}
        v-row.undraggable_table.mobile.sticky.table_row.mt-0(
          v-if="!statHasGame",
          :style="{ height: 'calc(' + $store.state.screenHeight + 'px - 254px)' }"
        )
          v-col.table.f_table.local.table_col(
            cols="12",
            :style="{ maxHeight: 'calc(' + $store.state.screenHeight + 'px - 254px)' }"
          )
            table
              thead
                tr.top_head
                  th.bdl(style="min-width: 45px")
                  th.bdr(colspan="3") 價值
                  th.bdr(colspan="2") 
                  th.bdr(colspan="3") 投籃
                  th.bdr(colspan="3") 三分
                  th.bdr(colspan="3") 罰球
                  th.bdr(colspan="3") 籃板
                  th.bdr(colspan="5")
                tr.bottom_head
                  th
                  th 季前
                  th 目前
                  th.bdr 趨勢
                  th.bdr 出賽
                  th.bdr 時間
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 命中
                  th 出手
                  th.bdr 命中率
                  th 進攻
                  th 防守
                  th.bdr 籃板
                  th 助攻
                  th 抄截
                  th 火鍋
                  th 失誤
                  th 得分
              tbody
                template(v-for="(player, index) in statCurrentPlayerList")
                  tr(:class="{ highlight_team: player.is_calculated }")
                    sticky-column.bdl(
                      colspan="7",
                      style="width: 100px; min-width: 100px",
                      :offset="0"
                    )
                      .player_container
                        .position {{ getArrangedPosition(player.arranged_position) }}
                        .player_info
                          p.player {{ player.name }}
                            span.pos {{ player.position }}
                          p.team {{ player.team_name }}
                            span.vs {{  player.opponent_team_name == null || player.opponent_team_name == ""? "" : 'vs'  }}
                            span.opponent {{ player.opponent_team_name }}
                    td(colspan="16")
                  tr(:class="{ highlight_team: player.is_calculated }")
                    td
                    td {{ player.preseason_value }}
                    td {{ player.current_value }}
                    td.bdr(
                      :class="[(((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) > 0 ? 'text_secondary' : 'text_light_gray']"
                    ) {{ player.preseason_value ? (((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) + '%' : '' }}
                    td.bdr {{ player.record_matches }}
                    td.bdr {{ player.box.secs >= 0 ? getMinutes(player.box.secs) : '-' }}
                    td {{ player.box.fgm != undefined ? player.box.fgm : '-' }}
                    td {{ player.box.fga != undefined ? player.box.fga : '-' }}
                    td.bdr {{ player.box.fg_percent != undefined ? (player.box.fg_percent * 100).toFixed(1) + '%' : '-' }}
                    td {{ player.box.three_pt_fgm != undefined ? player.box.three_pt_fgm : '-' }}
                    td {{ player.box.three_pt_fga != undefined ? player.box.three_pt_fga : '-' }}
                    td.bdr {{ player.box.three_pts_fg_percent != undefined ? (player.box.three_pts_fg_percent * 100).toFixed(1) + '%' : '-' }}
                    td {{ player.box.ftm != undefined ? player.box.ftm : '-' }}
                    td {{ player.box.fta != undefined ? player.box.fta : '-' }}
                    td.bdr {{ player.box.ft_percent != undefined ? (player.box.ft_percent * 100).toFixed(1) + '%' : '-' }}
                    td {{ player.box.off_reb != undefined ? player.box.off_reb : '-' }}
                    td {{ player.box.def_reb != undefined ? player.box.def_reb : '-' }}
                    td.bdr {{ player.box.reb != undefined ? player.box.reb : '-' }}
                    td {{ player.box.ast != undefined ? player.box.ast : '-' }}
                    td {{ player.box.stl != undefined ? player.box.stl : '-' }}
                    td {{ player.box.blk != undefined ? player.box.blk : '-' }}
                    td {{ player.box.tov != undefined ? player.box.tov : '-' }}
                    td {{ player.box.pts != undefined ? player.box.pts : '-' }}

      .action_cover(@click="isActionExpand = false", v-if="isActionExpand")
      .action_btns.d-block.d-md-none
        .action_wrapper(:class="{ expand: isActionExpand }")
          .action.foreign_btn(@click="showTableForeignDialog") 洋將
            span 交易
          .action.local_btn(@click="showTableLocalDialog") 本土
            span 交易
          .action.auto_btn(v-if="statHasGame", @click="autoDailyRoster") 自動
            span 登錄
            span 球員
          .block
        .action_btn(@click="isActionExpand = !isActionExpand")
          font-awesome-icon.magic(
            v-if="isActionExpand",
            :icon="['fas', 'chevron-down']"
          )
          font-awesome-icon.magic(v-else="", :icon="['fas', 'magic']")

    .team_average_stats(v-if="tab == 1")
      v-row.table_row.desktop.mt-0.px-9
        v-col.table.f_table.table_col
          table
            thead
              tr.top_head
                sticky-column.bdl.bdr2(
                  colspan="2",
                  style="width: 130px; min-width: 130px",
                  :offset="0"
                )
                th.bdr
                th.bdr(colspan="3") 價值
                th.bdr(colspan="2") 
                th.bdr(colspan="3") 投籃
                th.bdr(colspan="3") 三分
                th.bdr(colspan="3") 罰球
                th.bdr(colspan="3") 籃板
                th.bdr(colspan="5") 
              tr.bottom_head
                sticky-column.bdl(
                  style="width: 70px; min-width: 70px",
                  :offset="0"
                ) 名字
                sticky-column.bdr2(
                  style="width: 60px; min-width: 60px",
                  :offset="70"
                ) 位置
                th.bdr 隊伍
                th 季前
                th 目前
                th.bdr 趨勢
                th.bdr 出賽
                th.bdr 時間
                th 命中
                th 出手
                th.bdr 命中率
                th 命中
                th 出手
                th.bdr 命中率
                th 命中
                th 出手
                th.bdr 命中率
                th 進攻
                th 防守
                th.bdr 籃板
                th 助攻
                th 抄截
                th 火鍋
                th 失誤
                th 得分
            tbody
              tr(v-for="(player, index) in avgStatsPlayerList", :key="index")
                sticky-column.bdr.bdl(
                  style="width: 70px; min-width: 70px",
                  :offset="0"
                ) {{ player.name }}
                sticky-column.bdr2(
                  style="width: 60px; min-width: 60px",
                  :offset="70"
                ) {{ player.position }}
                td.bdr {{ player.team_name }}
                td {{ player.preseason_value }}
                td {{ player.current_value }}
                td.bdr(
                  :class="[(((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) > 0 ? 'text_secondary' : 'text_light_gray']"
                ) {{ (((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) + '%' }}
                td.bdr {{ player.record_matches }}
                td.bdr {{ player.box.avg_secs >= 0 ? getMinutes(player.box.avg_secs) : '-' }}
                td {{ player.box.avg_fgm != undefined ? player.box.avg_fgm.toFixed(1) : '-' }}
                td {{ player.box.avg_fga != undefined ? player.box.avg_fga.toFixed(1) : '-' }}
                td.bdr {{ player.box.avg_fg_percent != undefined ? (player.box.avg_fg_percent * 100).toFixed(1) + '%' : '-' }}
                td {{ player.box.avg_three_pt_fgm != undefined ? player.box.avg_three_pt_fgm.toFixed(1) : '-' }}
                td {{ player.box.avg_three_pt_fga != undefined ? player.box.avg_three_pt_fga.toFixed(1) : '-' }}
                td.bdr {{ player.box.avg_three_pt_fg_percent != undefined ? (player.box.avg_three_pt_fg_percent * 100).toFixed(1) + '%' : '-' }}
                td {{ player.box.avg_ftm != undefined ? player.box.avg_ftm.toFixed(1) : '-' }}
                td {{ player.box.avg_fta != undefined ? player.box.avg_fta.toFixed(1) : '-' }}
                td.bdr {{ player.box.avg_ft_percent != undefined ? (player.box.avg_ft_percent * 100).toFixed(1) + '%' : '-' }}
                td {{ player.box.avg_off_reb != undefined ? player.box.avg_off_reb.toFixed(1) : '-' }}
                td {{ player.box.avg_def_reb != undefined ? player.box.avg_def_reb.toFixed(1) : '-' }}
                td.bdr {{ player.box.avg_reb != undefined ? player.box.avg_reb.toFixed(1) : '-' }}
                td {{ player.box.avg_ast != undefined ? player.box.avg_ast.toFixed(1) : '-' }}
                td {{ player.box.avg_stl != undefined ? player.box.avg_stl.toFixed(1) : '-' }}
                td {{ player.box.avg_blk != undefined ? player.box.avg_blk.toFixed(1) : '-' }}
                td {{ player.box.avg_tov != undefined ? player.box.avg_tov.toFixed(1) : '-' }}
                td {{ player.box.avg_pts != undefined ? player.box.avg_pts.toFixed(1) : '-' }}
      v-row.table_row.mobile.sticky.mt-0(
        :style="{ height: 'calc(' + $store.state.screenHeight + 'px - 254px)' }"
      )
        v-col.table.f_table.table_col(
          :style="{ maxHeight: 'calc(' + $store.state.screenHeight + 'px - 254px)' }"
        )
          table
            thead
              tr.top_head
                th.bdl(style="min-width: 45px")
                th.bdr(colspan="3") 價值
                th.bdr(colspan="2") 
                th.bdr(colspan="3") 投籃
                th.bdr(colspan="3") 三分
                th.bdr(colspan="3") 罰球
                th.bdr(colspan="3") 籃板
                th.bdr(colspan="5") 
              tr.bottom_head
                th
                th 季前
                th 目前
                th.bdr 趨勢
                th.bdr 出賽
                th.bdr 時間
                th 命中
                th 出手
                th.bdr 命中率
                th 命中
                th 出手
                th.bdr 命中率
                th 命中
                th 出手
                th.bdr 命中率
                th 進攻
                th 防守
                th.bdr 籃板
                th 助攻
                th 抄截
                th 火鍋
                th 失誤
                th 得分
            tbody
              template(v-for="(player, index) in avgStatsPlayerList")
                tr
                  sticky-column.bdl(
                    colspan="6",
                    style="width: 100px; min-width: 100px",
                    :offset="0"
                  )
                    .player_container
                      .position {{ player.arranged_position == 'FOREIGN' ? '外籍' : '' }}
                      .player_info
                        p.player {{ player.name }}
                          span.pos {{ player.position }}
                        p.team {{ player.team_name }}
                  td(colspan="19")
                tr
                  td
                  td {{ player.preseason_value }}
                  td {{ player.current_value }}
                  td.bdr(
                    :class="[(((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) > 0 ? 'text_secondary' : 'text_light_gray']"
                  ) {{ (((player.current_value - player.preseason_value) / player.preseason_value) * 100).toFixed(1) + '%' }}
                  td.bdr {{ player.record_matches }}
                  td.bdr {{ player.box.avg_min >= 0 ? getMinutes(player.box.avg_min) : '-' }}
                  td {{ player.box.avg_fg_m != undefined ? player.box.avg_fg_m.toFixed(1) : '-' }}
                  td {{ player.box.avg_fg_a != undefined ? player.box.avg_fg_a.toFixed(1) : '-' }}
                  td.bdr {{ player.box.fg_percent != undefined ? (player.box.fg_percent * 100).toFixed(1) + '%' : '-' }}
                  td {{ player.box.avg_three_pts_m != undefined ? player.box.avg_three_pts_m.toFixed(1) : '-' }}
                  td {{ player.box.avg_three_pts_a != undefined ? player.box.avg_three_pts_a.toFixed(1) : '-' }}
                  td.bdr {{ player.box.three_pts_percent != undefined ? (player.box.three_pts_percent * 100).toFixed(1) + '%' : '-' }}
                  td {{ player.box.avg_ft_m != undefined ? player.box.avg_ft_m.toFixed(1) : '-' }}
                  td {{ player.box.avg_ft_a != undefined ? player.box.avg_ft_a.toFixed(1) : '-' }}
                  td.bdr {{ player.box.ft_percent != undefined ? (player.box.ft_percent * 100).toFixed(1) + '%' : '-' }}
                  td {{ player.box.avg_off_reb != undefined ? player.box.avg_off_reb.toFixed(1) : '-' }}
                  td {{ player.box.avg_def_reb != undefined ? player.box.avg_def_reb.toFixed(1) : '-' }}
                  td.bdr {{ player.box.avg_reb != undefined ? player.box.avg_reb.toFixed(1) : '-' }}
                  td {{ player.box.avg_ast != undefined ? player.box.avg_ast.toFixed(1) : '-' }}
                  td {{ player.box.avg_stl != undefined ? player.box.avg_stl.toFixed(1) : '-' }}
                  td {{ player.box.avg_blk != undefined ? player.box.avg_blk.toFixed(1) : '-' }}
                  td {{ player.box.avg_to != undefined ? player.box.avg_to.toFixed(1) : '-' }}
                  td {{ player.box.avg_pts != undefined ? player.box.avg_pts.toFixed(1) : '-' }}
    .team_opponents(v-if="tab == 2")
      v-row.table_row.desktop.mt-0.px-9
        v-col.table.f_table.table_col
          table
            thead
              tr.bottom_head
                sticky-column.bdl.bdr(
                  style="width: 70px; min-width: 70px",
                  :offset="0"
                ) 姓名
                sticky-column.bdl.bdr2(
                  style="width: 140px; min-width: 140px",
                  :offset="70"
                ) 隊伍
                th.bdr(v-for="game in oppDateList") {{ game.date }}
            tbody
              tr(v-for="player in oppPlayerList")
                sticky-column.bdl.bdr(
                  style="width: 70px; min-width: 70px",
                  :offset="0"
                ) {{ player.name }}
                sticky-column.bdl.bdr2(
                  style="width: 140px; min-width: 140px",
                  :offset="70"
                ) {{ player.team_name }}

                td.bdr(
                  v-if="player.games[0] != undefined",
                  :class="{ opponent_team: player.games[0].opponent_team_name != '' }"
                ) {{ player.games[0].opponent_team_name == '' ? '-' : player.games[0].opponent_team_name }}
                td.bdr(
                  v-if="player.games[1] != undefined",
                  :class="{ opponent_team: player.games[1].opponent_team_name != '' }"
                ) {{ player.games[1].opponent_team_name == '' ? '-' : player.games[1].opponent_team_name }}
                td.bdr(
                  v-if="player.games[2] != undefined",
                  :class="{ opponent_team: player.games[2].opponent_team_name != '' }"
                ) {{ player.games[2].opponent_team_name == '' ? '-' : player.games[2].opponent_team_name }}
                td.bdr(
                  v-if="player.games[3] != undefined",
                  :class="{ opponent_team: player.games[3].opponent_team_name != '' }"
                ) {{ player.games[3].opponent_team_name == '' ? '-' : player.games[3].opponent_team_name }}
                td.bdr(
                  v-if="player.games[4] != undefined",
                  :class="{ opponent_team: player.games[4].opponent_team_name != '' }"
                ) {{ player.games[4].opponent_team_name == '' ? '-' : player.games[4].opponent_team_name }}
                td.bdr(
                  v-if="player.games[5] != undefined",
                  :class="{ opponent_team: player.games[5].opponent_team_name != '' }"
                ) {{ player.games[5].opponent_team_name == '' ? '-' : player.games[5].opponent_team_name }}
                td(
                  v-if="player.games[6] != undefined",
                  :class="{ opponent_team: player.games[6].opponent_team_name != '' }"
                ) {{ player.games[6].opponent_team_name == '' ? '-' : player.games[6].opponent_team_name }}
      v-row.table_row.mobile.sticky.mt-0(
        :style="{ height: 'calc(' + $store.state.screenHeight + 'px - 254px)' }"
      )
        v-col.table.f_table.table_col(
          cols="12",
          :style="{ maxHeight: 'calc(' + $store.state.screenHeight + 'px - 254px)' }"
        )
          table
            thead
              tr.bottom_head
                sticky-column.bdl.bdr(
                  style="width: 70px; min-width: 70px",
                  :offset="0"
                ) 姓名
                sticky-column.bdl.bdr2(
                  style="width: 140px; min-width: 140px",
                  :offset="70"
                ) 隊伍
                th.bdr(v-for="game in oppDateList") {{ game.date }}
            tbody
              tr(v-for="player in oppPlayerList")
                sticky-column.bdl.bdr(
                  style="width: 70px; min-width: 70px",
                  :offset="0"
                ) {{ player.name }}
                sticky-column.bdl.bdr2(
                  style="width: 140px; min-width: 140px",
                  :offset="70"
                ) {{ player.team_name }}

                td.bdr(
                  v-if="player.games[0] != undefined",
                  :class="{ opponent_team: player.games[0].opponent_team_name != '' }"
                ) {{ player.games[0].opponent_team_name == '' ? '-' : player.games[0].opponent_team_name }}
                td.bdr(
                  v-if="player.games[1] != undefined",
                  :class="{ opponent_team: player.games[1].opponent_team_name != '' }"
                ) {{ player.games[1].opponent_team_name == '' ? '-' : player.games[1].opponent_team_name }}
                td.bdr(
                  v-if="player.games[2] != undefined",
                  :class="{ opponent_team: player.games[2].opponent_team_name != '' }"
                ) {{ player.games[2].opponent_team_name == '' ? '-' : player.games[2].opponent_team_name }}
                td.bdr(
                  v-if="player.games[3] != undefined",
                  :class="{ opponent_team: player.games[3].opponent_team_name != '' }"
                ) {{ player.games[3].opponent_team_name == '' ? '-' : player.games[3].opponent_team_name }}
                td.bdr(
                  v-if="player.games[4] != undefined",
                  :class="{ opponent_team: player.games[4].opponent_team_name != '' }"
                ) {{ player.games[4].opponent_team_name == '' ? '-' : player.games[4].opponent_team_name }}
                td.bdr(
                  v-if="player.games[5] != undefined",
                  :class="{ opponent_team: player.games[5].opponent_team_name != '' }"
                ) {{ player.games[5].opponent_team_name == '' ? '-' : player.games[5].opponent_team_name }}
                td(
                  v-if="player.games[6] != undefined",
                  :class="{ opponent_team: player.games[6].opponent_team_name != '' }"
                ) {{ player.games[6].opponent_team_name == '' ? '-' : player.games[6].opponent_team_name }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p 讀取中
  v-dialog(v-model="isDialogShow", width="500")
    v-date-picker.mt-4(
      v-model="date",
      locale="zh-tw",
      :picker-date.sync="pickerDate",
      :allowedDates="getAllowedDates",
      full-width,
      @click:date="closeDateDialog"
    )
  dialog-table-foreign.mt-15(
    @closeTableForeignDialog="closeTableForeignDialog",
    :dialogTableForeign="dialogTableForeignShow",
    :disabled="dialogTableForeignShow",
    @resetData="getSeasonData"
  )
  dialog-table-local.mt-15(
    @closeTableLocalDialog="closeTableLocalDialog",
    :dialogTableLocal="dialogTableLocalShow",
    :disabled="dialogTableLocalShow",
    @resetData="getSeasonData"
  )
  v-snackbar(
    v-model="snackbar",
    :timeout="timeout",
    :value="true",
    color="rgba(114,158,195,0.88)",
    elevation="0"
  )
    | {{ text }}
.create.text-center(v-else-if="!dialogLoadingShow && $store.state.user.accessToken && !isTeamExist")
  p(style="margin-top: 100px") 尚未建立球隊
  .btn.btn_water_blue.mt-6(@click="toCreateTeam") 創建球隊
.empty_cover.text-center(v-else-if="!$store.state.user.accessToken")
  img.d-none.d-sm-block(:src="blurCover")
  img.d-sm-none(:src="blurCoverM")
  .msg
    p(style="margin-top: 0px") 登入後查看隊伍數據
    .btn.btn_water_blue.mt-6(@click="toLogin") 前往登入
</template>

<script>
import gcAvatar from "@/assets/img/gc-avatar.png";
import StickyColumn from "@/components/common/Table/StickyColumn";
import {
  getSeasonData,
  checkMyTeam,
  getMyTeamByTab,
  setDailyRoster,
  autoDailyRoster,
} from "@/api/fantasy";
import blurCover from "@/assets/img/my_team/blur_cover.png";
import blurCoverM from "@/assets/img/my_team/blur_cover_m.png";
import draggable from "vuedraggable";
import DialogTableForeign from "@/components/dialog/DialogTableForeign";
import DialogTableLocal from "@/components/dialog/DialogTableLocal";
import DialogLoading from "@/components/dialog/DialogLoading";
import { getUserInfo } from "@/api/user";

export default {
  name: "MyTeam",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  props: {
    needUpdate: Boolean,
  },
  components: {
    DialogTableForeign,
    DialogTableLocal,
    DialogLoading,
    draggable,
    StickyColumn,
  },
  data() {
    return {
      dialogLoadingShow: true,
      userInfo: {},
      snackbar: false,
      text: "",
      timeout: 2000,
      gcAvatar,
      dialogTableLocalShow: false,
      dialogTableForeignShow: false,
      seasonData: {},
      blurCover,
      blurCoverM,
      isTeamExist: null,
      myTeamInfo: {},

      date: "",
      pickerDate: null,
      isDialogShow: false,

      tab: 0,

      statHasGameText: "",
      statHasGame: false,
      statPlayerGList: [],
      statPlayerFList: [],
      statPlayerCList: [],
      statPlayerUList: [],
      statPlayerUnusedList: [],

      statHasGamePlayerList: [],
      statForiegnPlayerList: [],

      statCurrentPlayerList: [],
      statForiegnUsedPlayerList: [],
      statForiegnUnusedPlayerList: [],

      statSelectPlayer: null,

      statPlayerList: [],

      avgStatsPlayerList: [],

      oppPlayerList: [],
      oppDateList: [],
      isTop: false,
      tableOld: 0,
      isActionExpand: false,
      taxAlertShow: false,

      attrs: "",
      on: "",
      tooltipShow: false,

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "GC Fantasy 我的球隊 | Game Changer",
        description: "GC Fantasy 我的球隊",
        keywords: "Game changer,籃球,會員,GC Fantasy",
        url: "https://www.gamechanger.tw/fantasy/myteam",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    needUpdate() {
      this.getUserInfo();
    },
    tab() {
      if (this.tab == 0) {
        this.getMyTeamByTab("STATS");
      } else if (this.tab == 1) {
        this.getMyTeamByTab("AVG_STATS");
      } else if (this.tab == 2) {
        this.getMyTeamByTab("OPPONENTS");
      }
    },
    date() {
      if (this.tab == 0) {
        this.getMyTeamByTab("STATS");
      } else if (this.tab == 1) {
        this.getMyTeamByTab("AVG_STATS");
      } else if (this.tab == 2) {
        this.getMyTeamByTab("OPPONENTS");
      }
    },
  },
  created() {
    this.getSeasonData();
    this.getUserInfo();
  },
  mounted() {
    this.$nextTick(() => {
      this.draggableTableTitle = document.querySelector(".draggableTableTitle");
      this.table = document.querySelector(".team_stats_body");
    });
  },
  methods: {
    async getUserInfo() {
      await getUserInfo().then((response) => {
        this.userInfo = response.data;
      });
    },
    async getSeasonData() {
      this.dialogLoadingShow = true;
      let temp = {
        state: "IN_SEASON",
      };
      await getSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];
        if (this.seasonData != undefined) {
          this.checkMyTeam();
        }
      });
      this.dialogLoadingShow = false;
    },
    async checkMyTeam() {
      if (this.$store.state.user.accessToken) {
        let temp = {
          season_id: this.seasonData.id,
        };
        await checkMyTeam(temp).then((response) => {
          this.isTeamExist = response.data.has_team;
          if (this.isTeamExist) {
            this.date = this.seasonData.focus_date;
            this.getAllowedDates();
            this.getMyTeamByTab("STATS");
          }
          this.myTeamInfo = response.data.team;
        });
      }
    },
    async getMyTeamByTab(tab) {
      this.dialogLoadingShow = true;

      let temp = {
        season_id: this.seasonData.id,
        date: this.date,
        tab: tab,
      };
      await getMyTeamByTab(temp).then((response) => {
        this.tooltipShow = false;
        if (tab == "STATS") {
          this.statHasGameText = response.data.date_state;
          this.statPlayerList = response.data.team;
          console.log("response.data=", response.data);
          if (this.statHasGameText == "NEW") {
            this.statHasGame = true;
            this.getArrangedPlayer();
            if (
              this.myTeamInfo.total_value >
              this.myTeamInfo.max_player_value + 20
            ) {
              this.tooltipShow = true;
            }
          } else {
            this.statHasGame = false;
            this.getCurrentPlayer(response.data.box);
            if (
              response.data.lock_value >
              this.myTeamInfo.max_player_value + 20
            ) {
              this.tooltipShow = true;
            }
          }
        } else if (tab == "AVG_STATS") {
          this.avgStatsPlayerList = response.data.team;
        } else if (tab == "OPPONENTS") {
          this.oppPlayerList = response.data.team;
          if (this.oppPlayerList.length > 0) {
            this.oppDateList = this.oppPlayerList[0].games;
          }
        }
      });
      this.dialogLoadingShow = false;
    },
    getAllowedDates(val) {
      if (val && this.seasonData.valid_dates.includes(val)) {
        return true;
      }
      return false;
    },
    getCurrentPlayer(box) {
      this.statPlayerGList = [];
      this.statPlayerFList = [];
      this.statPlayerCList = [];
      this.statPlayerUList = [];
      this.statForiegnUsedPlayerList = [];
      this.statPlayerUnusedList = [];
      this.statForiegnUnusedPlayerList = [];

      this.statCurrentPlayerList = [];

      for (let index in this.statPlayerList) {
        let temp = this.statPlayerList[index];
        switch (temp.arranged_position) {
          case "G":
            this.statPlayerGList.push(temp);
            break;
          case "F":
            this.statPlayerFList.push(temp);
            break;
          case "C":
            this.statPlayerCList.push(temp);
            break;
          case "U":
            this.statPlayerUList.push(temp);
            break;
          case "FOREIGN":
            if (temp.is_calculated) {
              this.statForiegnUsedPlayerList.push(temp);
            } else {
              this.statForiegnUnusedPlayerList.push(temp);
            }
            break;
          default:
            this.statPlayerUnusedList.push(temp);
            break;
        }
      }
      if (this.statPlayerGList.length == 0) {
        let tempG = {
          arranged_position: "G",
          is_calculated: true,
          box: {},
        };
        this.statCurrentPlayerList.push(tempG);
      } else {
        this.statCurrentPlayerList.push(...this.statPlayerGList);
      }

      if (this.statPlayerFList.length == 0) {
        let tempF = {
          arranged_position: "F",
          is_calculated: true,
          box: {},
        };
        this.statCurrentPlayerList.push(tempF);
      } else {
        this.statCurrentPlayerList.push(...this.statPlayerFList);
      }

      if (this.statPlayerCList.length == 0) {
        let tempC = {
          arranged_position: "C",
          is_calculated: true,
          box: {},
        };
        this.statCurrentPlayerList.push(tempC);
      } else {
        this.statCurrentPlayerList.push(...this.statPlayerCList);
      }

      if (this.statPlayerUList.length == 0) {
        let tempU1 = {
          arranged_position: "U",
          is_calculated: true,
          box: {},
        };
        let tempU2 = {
          arranged_position: "U",
          is_calculated: true,
          box: {},
        };
        this.statCurrentPlayerList.push(tempU1);
        this.statCurrentPlayerList.push(tempU2);
      } else if (this.statPlayerUList.length == 1) {
        this.statCurrentPlayerList.push(...this.statPlayerUList);
        let tempU1 = {
          arranged_position: "U",
          is_calculated: true,
          box: {},
        };
        this.statCurrentPlayerList.push(tempU1);
      } else {
        this.statCurrentPlayerList.push(...this.statPlayerUList);
      }

      this.statCurrentPlayerList.push(...this.statForiegnUsedPlayerList);
      this.statCurrentPlayerList.push(...this.statPlayerUnusedList);
      this.statCurrentPlayerList.push(...this.statForiegnUnusedPlayerList);

      let temp = {
        arranged_position: "總計",
        is_calculated: true,
        box: box,
      };

      this.statCurrentPlayerList.push(temp);
    },
    getArrangedPlayer() {
      this.statPlayerGList = [];
      this.statPlayerFList = [];
      this.statPlayerCList = [];
      this.statPlayerUList = [];
      this.statPlayerUnusedList = [];
      this.statForiegnPlayerList = [];
      this.statHasGamePlayerList = [];
      for (let index in this.statPlayerList) {
        let temp = this.statPlayerList[index];
        switch (temp.arranged_position) {
          case "G":
            this.statPlayerGList.push(temp);
            break;
          case "F":
            this.statPlayerFList.push(temp);
            break;
          case "C":
            this.statPlayerCList.push(temp);
            break;
          case "U":
            this.statPlayerUList.push(temp);
            break;
          case "FOREIGN":
            this.statForiegnPlayerList.push(temp);
            break;
          default:
            this.statPlayerUnusedList.push(temp);
            break;
        }
      }
      this.statHasGamePlayerList.push(...this.statForiegnPlayerList);
      if (this.statPlayerGList.length == 0) {
        let tempG = {
          arranged_position: "G",
          box: {},
        };
        this.statHasGamePlayerList.push(tempG);
      } else {
        this.statHasGamePlayerList.push(...this.statPlayerGList);
      }

      if (this.statPlayerFList.length == 0) {
        let tempF = {
          arranged_position: "F",
          box: {},
        };
        this.statHasGamePlayerList.push(tempF);
      } else {
        this.statHasGamePlayerList.push(...this.statPlayerFList);
      }

      if (this.statPlayerCList.length == 0) {
        let tempC = {
          arranged_position: "C",
          box: {},
        };
        this.statHasGamePlayerList.push(tempC);
      } else {
        this.statHasGamePlayerList.push(...this.statPlayerCList);
      }

      if (this.statPlayerUList.length == 0) {
        let tempU1 = {
          arranged_position: "U",
          box: {},
        };
        let tempU2 = {
          arranged_position: "U",
          box: {},
        };
        this.statHasGamePlayerList.push(tempU1);
        this.statHasGamePlayerList.push(tempU2);
      } else if (this.statPlayerUList.length == 1) {
        this.statHasGamePlayerList.push(...this.statPlayerUList);
        let tempU1 = {
          arranged_position: "U",
          box: {},
        };
        this.statHasGamePlayerList.push(tempU1);
      } else {
        this.statHasGamePlayerList.push(...this.statPlayerUList);
      }

      this.statHasGamePlayerList.push(...this.statPlayerUnusedList);
    },
    playerClick(player, clickPosition) {
      let tempClickFlag = 0;
      if (player.arranged_position == "FOREIGN") {
        return;
      }
      if (this.statSelectPlayer != null) {
        if (this.statSelectPlayer.id == player.id) {
          this.statSelectPlayer = null;
          let tempList = Object.assign([], this.statHasGamePlayerList);
          this.statHasGamePlayerList = [];
          for (let index in tempList) {
            let tempPlayer = tempList[index];
            if (tempPlayer.arranged_position != "Empty") {
              tempPlayer.view_state = "";
              this.statHasGamePlayerList.push(tempPlayer);
            }
          }
        } else {
          if (player.view_state == "N") {
            return;
          }
          if (
            !this.statSelectPlayer.arranged_position ||
            this.statSelectPlayer.arranged_position == ""
          ) {
            for (let index in this.statHasGamePlayerList) {
              let temp = this.statHasGamePlayerList[index];
              if (this.statSelectPlayer.id == temp.id) {
                temp.arranged_position = player.arranged_position;
                break;
              }
            }
            this.statSelectPlayer = null;
            player.arranged_position = "";
            this.beginSetRoster();
          } else {
            if (player.arranged_position == "Empty") {
              for (let index in this.statHasGamePlayerList) {
                let temp = this.statHasGamePlayerList[index];
                if (this.statSelectPlayer.id == temp.id) {
                  temp.arranged_position = "";
                  break;
                }
              }
              this.statSelectPlayer = null;
              this.beginSetRoster();
            } else if (
              !player.arranged_position ||
              player.arranged_position == ""
            ) {
              player.arranged_position =
                this.statSelectPlayer.arranged_position;
              for (let index in this.statHasGamePlayerList) {
                let temp = this.statHasGamePlayerList[index];
                if (this.statSelectPlayer.id == temp.id) {
                  temp.arranged_position = "";
                  break;
                }
              }
              this.statSelectPlayer = null;
              this.beginSetRoster();
            } else if (player.arranged_position != "") {
              let tempPosition = this.statSelectPlayer.arranged_position;
              for (let index in this.statHasGamePlayerList) {
                let temp = this.statHasGamePlayerList[index];
                if (this.statSelectPlayer.id == temp.id) {
                  temp.arranged_position = player.arranged_position;
                  break;
                }
              }
              if (tempPosition == "G") {
                if (player.position == "G") {
                  player.arranged_position = "G";
                } else {
                  player.arranged_position = "";
                }
              } else if (tempPosition == "F") {
                if (player.position == "F") {
                  player.arranged_position = "F";
                } else {
                  player.arranged_position = "";
                }
              } else if (tempPosition == "C") {
                if (player.position == "C") {
                  player.arranged_position = "C";
                } else {
                  player.arranged_position = "";
                }
              } else if (tempPosition == "U") {
                player.arranged_position = "U";
              }
              this.statSelectPlayer = null;
              this.beginSetRoster();
            }
          }
        }
      } else {
        this.statSelectPlayer = player;
        let tempList = Object.assign([], this.statHasGamePlayerList);
        this.statHasGamePlayerList = [];

        if (!player.arranged_position || player.arranged_position == "") {
          for (let index in tempList) {
            let tempPlayer = tempList[index];
            if (tempPlayer.id == player.id) {
              tempPlayer.view_state = "S";
            } else {
              if (tempPlayer.arranged_position == "FOREIGN") {
                tempPlayer.view_state = "N";
                this.statHasGamePlayerList.push(tempPlayer);
                continue;
              }
              if (player.position == "G") {
                if (
                  tempPlayer.arranged_position == "G" ||
                  tempPlayer.arranged_position == "U"
                ) {
                  tempPlayer.view_state = "Y";
                } else {
                  tempPlayer.view_state = "N";
                }
              } else if (player.position == "F") {
                if (
                  tempPlayer.arranged_position == "F" ||
                  tempPlayer.arranged_position == "U"
                ) {
                  tempPlayer.view_state = "Y";
                } else {
                  tempPlayer.view_state = "N";
                }
              } else if (player.position == "C") {
                if (
                  tempPlayer.arranged_position == "C" ||
                  tempPlayer.arranged_position == "U"
                ) {
                  tempPlayer.view_state = "Y";
                } else {
                  tempPlayer.view_state = "N";
                }
              }
            }
            this.statHasGamePlayerList.push(tempPlayer);
          }
        } else {
          for (let index in tempList) {
            let tempPlayer = tempList[index];
            if (tempPlayer.arranged_position == "U") {
              tempClickFlag = tempClickFlag + 1;
            }
            if (tempPlayer.arranged_position == "FOREIGN") {
              tempPlayer.view_state = "N";
              this.statHasGamePlayerList.push(tempPlayer);
              continue;
            }
            if (player.id) {
              if (player.id == tempPlayer.id) {
                tempPlayer.view_state = "S";
              } else {
                if (player.position == "G") {
                  if (
                    tempPlayer.arranged_position == "U" ||
                    tempPlayer.arranged_position == "G" ||
                    tempPlayer.position == "G"
                  ) {
                    tempPlayer.view_state = "Y";
                  } else {
                    tempPlayer.view_state = "N";
                  }
                } else if (player.position == "F") {
                  if (
                    tempPlayer.arranged_position == "U" ||
                    tempPlayer.arranged_position == "F" ||
                    tempPlayer.position == "F"
                  ) {
                    tempPlayer.view_state = "Y";
                  } else {
                    tempPlayer.view_state = "N";
                  }
                }
                if (player.position == "C") {
                  if (
                    tempPlayer.arranged_position == "U" ||
                    tempPlayer.arranged_position == "C" ||
                    tempPlayer.position == "C"
                  ) {
                    tempPlayer.view_state = "Y";
                  } else {
                    tempPlayer.view_state = "N";
                  }
                }
              }
            } else {
              if (player.arranged_position == tempPlayer.arranged_position) {
                if (player.arranged_position == "U") {
                  if (clickPosition == 6 && tempClickFlag == 1) {
                    tempPlayer.view_state = "S";
                  } else if (clickPosition == 7 && tempClickFlag == 2) {
                    tempPlayer.view_state = "S";
                  } else {
                    tempPlayer.view_state = "Y";
                  }
                } else {
                  tempPlayer.view_state = "S";
                }
              } else {
                if (player.arranged_position == "G") {
                  if (tempPlayer.position == "G") {
                    tempPlayer.view_state = "Y";
                  } else {
                    tempPlayer.view_state = "N";
                  }
                } else if (player.arranged_position == "F") {
                  if (tempPlayer.position == "F") {
                    tempPlayer.view_state = "Y";
                  } else {
                    tempPlayer.view_state = "N";
                  }
                } else if (player.arranged_position == "C") {
                  if (tempPlayer.position == "C") {
                    tempPlayer.view_state = "Y";
                  } else {
                    tempPlayer.view_state = "N";
                  }
                } else if (player.arranged_position == "U") {
                  if (tempPlayer.position) {
                    tempPlayer.view_state = "Y";
                  } else {
                    tempPlayer.view_state = "N";
                  }
                }
              }
            }
            this.statHasGamePlayerList.push(tempPlayer);
          }

          if (player.id) {
            let tempEmpty = {
              arranged_position: "Empty",
              box: {},
              view_state: "Y",
            };
            this.statHasGamePlayerList.push(tempEmpty);
          }
        }
      }
    },
    beginSetRoster() {
      let rosterGID = "";
      let rosterFID = "";
      let rosterCID = "";
      let rosterUID = "";
      for (let index in this.statHasGamePlayerList) {
        let temp = this.statHasGamePlayerList[index];
        if (temp.arranged_position == "G" && temp.id) {
          rosterGID = temp.id;
        } else if (temp.arranged_position == "F" && temp.id) {
          rosterFID = temp.id;
        } else if (temp.arranged_position == "C" && temp.id) {
          rosterCID = temp.id;
        } else if (temp.arranged_position == "U" && temp.id) {
          if (rosterUID == "") {
            rosterUID = temp.id;
          } else {
            rosterUID = rosterUID + "," + temp.id;
          }
        }
      }
      let temp = {
        season_id: this.seasonData.id,
        date: this.date,
        roster_g_ids: rosterGID,
        roster_f_ids: rosterFID,
        roster_c_ids: rosterCID,
        roster_u_ids: rosterUID,
      };
      setDailyRoster(temp).then(() => {
        this.getMyTeamByTab("STATS");
        this.showMsg("球員位置已調整!!");
      });
    },
    async autoDailyRoster() {
      this.dialogLoadingShow = true;

      if (this.isActionExpand) {
        this.isActionExpand = false;
      }
      if (this.statHasGameText == "NEW") {
        let temp = {
          season_id: this.seasonData.id,
          date: this.date,
        };
        await autoDailyRoster(temp).then(() => {
          this.showMsg("自動登錄球員成功！");
          this.getMyTeamByTab("STATS");
        });
      }
      this.dialogLoadingShow = false;
    },
    toCreateTeam() {
      this.$emit("showNormalDialog");
      this.$router.push({ name: "CreateTeam" });
    },
    toLogin() {
      this.$router.push({ name: "Login" });
    },
    getArrangedPosition(position) {
      if (position == "FOREIGN") {
        return "外籍";
      } else {
        return position;
      }
    },
    topAction() {
      document.querySelector(".draggable_table.mobile .local");
      if (!this.isTop) {
        if (
          this.$refs.draggableTableTitle.getBoundingClientRect().top - 30 <=
          0
        ) {
          this.isNavTop(true);
        }
      }
    },
    isNavTop(isTop) {
      if (isTop) {
        this.$refs.table.classList.add("fixed");
        this.isTop = true;
      } else {
        this.$refs.table.classList.remove("fixed");
        this.isTop = false;
      }
    },
    openDateDialog() {
      this.isDialogShow = true;
    },
    closeDateDialog() {
      this.isDialogShow = false;
    },
    getDateArrowState(direction) {
      let currentDateIndex = this.seasonData.valid_dates.indexOf(this.date);
      if ("PREV" == direction) {
        if (currentDateIndex == 0) {
          return true;
        } else {
          return false;
        }
      } else if ("NEXT" == direction) {
        if (currentDateIndex == this.seasonData.valid_dates.length - 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    toPrevDate() {
      let currentDateIndex = this.seasonData.valid_dates.indexOf(this.date);
      if (currentDateIndex != -1) {
        if (currentDateIndex != 0) {
          this.date = this.seasonData.valid_dates[currentDateIndex - 1];
        }
      }
    },
    toNextDate() {
      let currentDateIndex = this.seasonData.valid_dates.indexOf(this.date);
      if (currentDateIndex != -1) {
        if (currentDateIndex != this.seasonData.valid_dates.length - 1) {
          this.date = this.seasonData.valid_dates[currentDateIndex + 1];
        }
      }
    },
    showTableForeignDialog() {
      if (this.isActionExpand) {
        this.isActionExpand = false;
      }
      this.dialogTableForeignShow = true;
    },
    closeTableForeignDialog() {
      this.dialogTableForeignShow = false;
    },
    showTableLocalDialog() {
      if (this.isActionExpand) {
        this.isActionExpand = false;
      }
      this.dialogTableLocalShow = true;
    },
    closeTableLocalDialog() {
      this.dialogTableLocalShow = false;
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
    getGameStatus() {
      switch (this.statHasGameText) {
        case "NO_GAME":
          return "沒有比賽";
        case "NEW":
          return "比賽未開始";
        case "LOCK":
          return "比賽已鎖定";
        case "END":
          return "比賽結束";
        default:
          return "沒有比賽";
      }
    },
    showMsg(msg) {
      this.text = msg;
      this.snackbar = true;
    },
    showAchievementDialog() {
      this.$emit("showAchievementDialog");
    },
    toMemberCenter() {
      this.$router.push({
        name: "MemberCenter",
        params: {
          tab: 1,
        },
      });
    },
    toThousands(num) {
      var temp = num + "";
      var result = "";
      while (temp.length > 3) {
        result = "," + temp.slice(-3) + result;
        temp = temp.slice(0, temp.length - 3);
      }
      if (temp) {
        result = temp + result;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/fantasy/my_team_league";

.draggable_table.mobile,
.undraggable_table.mobile,
.team_average_stats .mobile,
.team_opponents .mobile {
  display: none;
}

.draggable_table,
.undraggable_table {
  table td {
    background-color: #f4f4f4;
  }

  table th {
    background-color: #f4f4f4;
  }

  tbody th {
    background-color: #f4f4f4;
  }
}

tbody {
  th.sticky {
    // color: #51769d;
    font-weight: normal;
  }
}

.foreign {
  tbody {
    th.sticky {
      color: #1f1f1f;
      font-weight: normal;
    }
  }
}

.team_stats {
  box-shadow: 0 0 30px 0 #e1e8f0;
  width: 100%;
  overflow: hidden;
}

.team_stats_header {
  width: 100%;
  height: 64px;
}

.team_states_menu {
  width: 70%;
  height: 100%;
  background: #6c7a8e;
  padding-left: 12px;

  .v-tab {
    color: rgba(white, 0.8) !important;
    font-weight: normal;
    font-size: 14px;
  }

  .v-tab:not(:last-child) {
    border-right: 1px solid rgba(white, 0.5);
  }

  .v-tab--active {
    color: #e0efff !important;
    font-weight: bold;
  }
}

.date {
  width: 30%;
  height: 100%;
}

.date_picker {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;

  svg {
    color: #6c7a8e;
  }

  svg,
  span {
    cursor: pointer;
  }

  svg.arrow_disable {
    opacity: 0.3;
    cursor: default;
  }
}

.team_stats_body {
  width: 100%;
  background: white;
}

.tax_alert {
  font-size: 12px;
  color: #e2252f;

  svg {
    width: 14px;
    height: 14px;
  }

  span {
    vertical-align: middle;
  }
}

.game_status {
  color: #ababab;
  border: rgba(#ababab, 0.5) 1px solid;
  padding: 5px;
  font-size: 12px;
}

.game_status.ready {
  color: #e4a86c;
  border-color: rgba(#e4a86c, 0.5);
}

.game_status.going {
  color: #e07575;
  border-color: rgba(#e07575, 0.5);
}

.game_status.ending {
  color: #6b6b6b;
  border-color: rgba(#6b6b6b, 0.5);
}

@media (max-width: 959px) {
  .draggable_table.desktop,
  .undraggable_table.desktop,
  .team_average_stats .desktop,
  .team_opponents .desktop {
    display: none;
  }

  .draggable_table.mobile,
  .undraggable_table.mobile,
  .team_average_stats .mobile,
  .team_opponents .mobile {
    display: flex;
  }

  .sticky {
    thead {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  .draggable_table.mobile,
  .undraggable_table.mobile,
  .team_average_stats .mobile,
  .team_opponents .mobile {
    padding-top: 0;
    padding-bottom: 0px;

    .table {
      padding-bottom: 60px;
    }
  }

  // .team_stats_header {
  //   position: absolute;
  //   top: 0;
  // }
  .my_team {
    position: relative;
  }
  // .team_stats.wrapper {
  //   position: absolute;
  //   top: 0;
  // }

  .team_stats.radius_l {
    position: fixed !important;
  }

  .v-snack {
    bottom: 60px !important;
  }

  .team_states_tabs {
    width: 100%;
  }

  .team_states_menu {
    width: 100%;
    height: 100%;
    background: #f7f7f7;
    padding-left: 0px;

    ::v-deep .v-tab {
      color: #a8a8a8 !important;
      font-weight: normal;
      font-size: 12px;
      height: 30px;
      padding: 0;
      flex: 1 1 auto;
      white-space: nowrap;
    }

    ::v-deep
      .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
      > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
      .v-slide-group__prev {
      display: none;
    }

    .v-tab:not(:last-child) {
      border-right: 1px solid rgba(white, 0.5);
    }

    .v-tab--active {
      color: #6c7a8e !important;
      font-weight: bold;
    }

    ::v-deep .v-tabs-bar__content {
      justify-content: center;
    }

    ::v-deep .theme--light.v-tabs > .v-tabs-bar {
      height: 30px;
    }
  }

  .team_stats {
    border-radius: 0 !important;
    box-shadow: none;
  }

  .date {
    width: 100%;
    align-items: center;
    border-top: 1px solid #f6f6f6;
  }

  .date_picker {
    background-color: white;
    width: auto;
    min-height: 30px;
    font-size: 14px;
    flex-grow: 1;
  }

  .tax_alert {
    svg {
      width: 18px;
      height: 18px;
    }

    span {
      vertical-align: middle;
    }
  }

  .game_status {
    padding: 0;
    font-size: 11px;
    line-height: 20px;
    height: 20px;
    padding-left: 2px;
    padding-right: 2px;
    margin-right: 10px;
  }

  .team_stats_header {
    height: auto;
  }

  .user_desc_wrapper {
    display: flex;

    .level {
      margin-left: 10px;
    }
  }

  .action_cover {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 200;
    background: rgba(0, 0, 0, 0.5);
  }

  .action_btns {
    position: fixed;
    bottom: 150px;
    right: 5px;
    width: 50px;
    height: 240px;
    cursor: pointer;
    z-index: 200;

    .action_btn {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50px;
      height: 50px;
      background-color: #8eb0d6;
      color: white;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(#3b3d40, 0.2);
    }

    .magic {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
    }

    .action_wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
      right: 0;
      border-radius: 25px;
      background: #f4f7fb;
      max-height: 0px;
      transition: all 0.5s;
      overflow: hidden;

      .action {
        text-align: center;
        width: 50px;
        margin: auto;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;

        span {
          display: block;
        }
      }

      .action:not(:last-child) {
        font-size: 12px;
        border-bottom: white 1px solid;
        font-weight: bold;
      }
      .foreign_btn {
        color: #d88e8f;
      }
      .local_btn {
        color: #8e93d6;
      }
      .auto_btn {
        color: #537fad;
      }
    }

    .action_wrapper.expand {
      max-height: 240px;
    }

    .block {
      height: 50px;
    }
  }

  .player_container {
    text-align: left;
    display: flex;
    align-items: center;

    .position {
      display: block;
      font-size: 13px;
      width: 33px;
      height: 33px;
      line-height: 33px;
      text-align: center;
    }

    .player_info {
      margin-left: 10px;

      p {
        margin: 0;
        line-height: 30px;
      }

      span {
        margin-left: 5px;
      }
    }
    .pos {
      color: #8d8d8d;
    }

    .opponent {
      color: #8e94d6;
    }

    .vs {
      color: #a3a3a3;
    }
  }

  .highlight_team {
    .player_container {
      .position {
        border-radius: 50%;
        background: rgba(#ffffff, 0.5);
        border: 1px solid rgba(#6c8fff, 0.5);
        color: #6c8fff;
      }
    }

    .text_secondary {
      color: $secondary !important;
    }
  }

  .highlight_team.foreign {
    .player_container {
      .position {
        border-radius: 50%;
        background: rgba(#ffffff, 0.5);
        border: 1px solid rgba(#dda19f, 0.5);
        color: #d07576;
      }
    }
  }
}

@media (min-width: 768px) {
  .position {
    display: block;
    font-size: 13px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
  }

  .player_info {
    margin-left: 10px;

    p {
      margin: 0;
      line-height: 30px;
    }

    span {
      margin-left: 5px;
    }
  }
  .pos {
    color: #8d8d8d;
  }

  .opponent {
    color: #8e94d6;
  }

  .vs {
    color: #a3a3a3;
  }

  .highlight_team {
    .position {
      border-radius: 50%;
      background: rgba(#ffffff, 0.5);
      border: 1px solid rgba(#6c8fff, 0.5);
      color: #6c8fff;
    }

    .text_secondary {
      color: $secondary !important;
    }
  }

  .highlight_team.foreign {
    .position {
      border-radius: 50%;
      background: rgba(#ffffff, 0.5);
      border: 1px solid rgba(#dda19f, 0.5);
      color: #d07576;
    }
  }
}

@media (max-width: 767px) {
}
.undraggable_table.desktop {
  .highlight_team:last-child {
    .position {
      background: transparent;
      border: none;
      color: #1f1f1f;
    }
  }
}

.undraggable_table.mobile {
  .highlight_team:nth-last-child(2) {
    .position {
      background: transparent;
      border: none;
      color: #1f1f1f;
    }
  }
}
</style>
