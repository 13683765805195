<template lang="pug">
v-dialog(
  v-model="dialogTableLocal",
  :scrollable="true",
  max-width="100%",
  persistent
)
  v-card
    v-container
      v-card-title.flex-column.justify-center.pa-0.py-md-3
        v-row.bottom_actions.w-100(style="font-size: 14px")
          v-col.pr-0.pr-md-1(cols="12", sm="4", md="6")
            .text-h5 交易本土
          v-col.pr-0.pr-md-1(cols="6", sm="4", md="3")
            select(v-model="position", @change="filterChange")
              option(value="ALL") 所有位置
              option(value="G") G
              option(value="F") F
              option(value="C") C
          v-col(cols="6", sm="4", md="3")
            input(placeholder="球員姓名", v-model="nameText")
    v-card-text.pa-0
      v-container
        v-row
          v-col(cols="12", md="6")
            .selected_player_list.d-flex.flex-column
              .selected_player_list_header.d-flex.align-center.justify-space-between
                svg-icon(
                  :class="{ arrow_disable: myTeamLocalPlayerPage == 1 }",
                  @click="onSelectedPlayerLeftArrowClick()",
                  iconClass="arrow_left_round_rect",
                  className="arrow_left_round_rect"
                )
                .d-flex.align-center
                  p 我的球隊
                  p.font-weight-light.ml-2 {{ myTeamLocalPlayerPage }}/{{ currentMyTeamLocalPlayerList.length > 0 ? Math.ceil(currentMyTeamLocalPlayerList.length / 10) : 1 }}
                svg-icon(
                  :class="{ arrow_disable: myTeamLocalPlayerPage == (currentMyTeamLocalPlayerList.length > 0 ? Math.ceil(currentMyTeamLocalPlayerList.length / 10) : 1) }",
                  @click="onSelectedPlayerRightArrowClick()",
                  iconClass="arrow_right_round_rect",
                  className="arrow_right_round_rect"
                )
              .selected_player_list_body.flex-grow-1
                v-row.table_row.mt-0
                  v-col.table.f_table.table_col
                    table
                      thead
                        tr.bottom_head
                          sticky-column(
                            style="width: 70px; min-width: 70px",
                            :offset="0"
                          ) 名字
                          th 隊伍
                          th 位置
                          th 季前價值
                          th 目前價值

                      tbody
                        tr(
                          v-for="(player, index) in currentMyTeamLocalPlayerList",
                          :key="index",
                          @click="myLocalPlayerClick(player)",
                          :class="{ player_selected: myTeamLocalPlayerIDSelect.includes(player.id) }"
                        )
                          sticky-column(
                            style="width: 70px; min-width: 70px",
                            :offset="0"
                          ) {{ player.name }}
                          td {{ player.team_name }}
                          td {{ player.position }}
                          td {{ player.preseason_value }}
                          td {{ player.current_value }}
          v-col(cols="12", md="6")
            .player_list
              .player_list_header.d-flex.align-center.justify-space-between
                svg-icon(
                  :class="{ arrow_disable: playerPage == 1 }",
                  @click="onPlayerLeftArrowClick()",
                  iconClass="arrow_left_round_rect",
                  className="arrow_left_round_rect"
                )
                p 球員列表
                .d-flex.align-center
                  p.font-weight-light {{ playerPage }}/{{ tempPlayerList.length > 0 ? Math.ceil(tempPlayerList.length / 10) : 1 }}
                  svg-icon.ml-2(
                    :class="{ arrow_disable: playerPage == (tempPlayerList.length > 0 ? Math.ceil(tempPlayerList.length / 10) : 1) }",
                    @click="onPlayerRightArrowClick()",
                    iconClass="arrow_right_round_rect",
                    className="arrow_right_round_rect"
                  )
              .player_list_body
                v-row.table_row.mt-0
                  v-col.table.f_table.table_col
                    table
                      thead
                        tr.bottom_head
                          sticky-column(
                            style="width: 70px; min-width: 70px",
                            :offset="0"
                          ) 名字
                          th 隊伍
                          th 位置
                          th 季前價值
                          th 目前價值
                      tbody
                        tr(
                          v-for="(player, index) in currentPlayerList",
                          @click="playerListClick(player)",
                          :class="{ player_selected: playerIDListSelect.includes(player.id) }"
                        )
                          sticky-column(
                            style="width: 70px; min-width: 70px",
                            :offset="0"
                          ) {{ player.name }}
                          td {{ player.team_name }}
                          td {{ player.position }}
                          td {{ player.preseason_value }}
                          td {{ player.current_value }}
    v-card-actions
      v-spacer
      .btn_actions.d-flex.flex-column.flex-md-row.align-md-center
        .card.d-flex.flex-column.flex-md-row.align-md-center
          .amount_container.d-flex.d-md-block.flex-grow-0.flex-shrink-0
            .amount
              p.d-inline-block 本土剩餘交易次數
              span.num {{ seasonData.is_transaction_unlimited ? '無限' : transactionTime + ' 次' }}
            .amount
              p.d-inline-block 剩餘價值
              span.num(
                :class="[leftValue < 0 ? 'text_secondary' : 'text_black']"
              ) {{ leftValue }}
          .trans.align-center.flex-grow-1.ml-0.ml-md-10
            .before.d-flex.align-center
              span.title.flex-shrink-0 交易前
              .arrow.flex-shrink-0
              .player_wrapper.flex-grow-1.flex-shrink-1
                span.current_player(v-if="myTeamLocalPlayerSelect.length == 0") 選擇球員
                span.current_player.selected(
                  v-if="myTeamLocalPlayerSelect.length > 0",
                  v-for="(player, index) in myTeamLocalPlayerSelect"
                ) {{ player.name }}
            .after.d-flex.align-center
              span.title.flex-shrink-0 交易後
              font-awesome-icon.arrow.flex-shrink-0(
                :icon="['fas', 'long-arrow-alt-right']"
              )
              .player_wrapper.flex-grow-1.flex-shrink-1
                span.new_player(v-if="playerListSelect.length == 0") 選擇球員
                span.new_player.selected(
                  v-if="playerListSelect.length > 0",
                  v-for="(player, index) in playerListSelect"
                ) {{ player.name }}
        .btns_container.d-flex.flex-grow-0.flex-shrink-0.ml-3
          .btn(
            :class="[playerListSelect.length > 0 && myTeamLocalPlayerSelect.length > 0 ? 'btn_water_blue' : 'btn_disable']",
            @click="confirmTransaction"
          ) 確認交易
          .btn.btn_gray_hollow.ml-3(
            small,
            color="darken-1",
            @click="closeDialog"
          ) 關閉
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import {
  getSeasonData,
  checkMyTeam,
  getSeasonDraftData,
  getCurrentRoster,
  createTransaction,
} from "@/api/fantasy";
import { validStringEmpty } from "@/utils/validate";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";

export default {
  name: "DialogTableLocal",
  props: {
    title: String,
    dialogTableLocal: Boolean,
    icon: String,
  },
  components: {
    StickyColumn,
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      seasonData: {},
      transactionTime: 0,
      leftValue: 0,

      position: "ALL",
      nameText: "",

      playerPage: 1,
      playerList: [],
      tempPlayerList: [],
      currentPlayerList: [],
      playerListSelect: [],
      playerIDListSelect: [],

      myTeamInfo: {},
      myTeamLocalPlayerPage: 1,
      myTeamPlayerList: [],
      myTeamLocalPlayerList: [],
      currentMyTeamLocalPlayerList: [],
      myTeamLocalPlayerSelect: [],
      myTeamLocalPlayerIDSelect: [],

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
    };
  },
  watch: {
    nameText() {
      this.filterChange();
    },
    dialogTableLocal() {
      this.seasonData = {};
      this.transactionTime = 0;
      this.leftValue = 0;

      this.position = "ALL";
      this.nameText = "";

      this.playerPage = 1;
      this.playerList = [];
      this.tempPlayerList = [];
      this.currentPlayerList = [];
      this.playerListSelect = [];
      this.playerIDListSelect = [];

      this.myTeamLocalPlayerPage = 1;
      this.myTeamPlayerList = [];
      this.myTeamLocalPlayerList = [];
      this.currentMyTeamLocalPlayerList = [];
      this.myTeamLocalPlayerSelect = [];
      this.myTeamLocalPlayerIDSelect = [];
      if (this.dialogTableLocal) {
        this.getSeasonData();
      }
    },
  },
  methods: {
    getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];
        this.checkMyTeam();
        this.getCurrentRoster();
      });
    },
    checkMyTeam() {
      if (this.$store.state.user.accessToken) {
        let temp = {
          season_id: this.seasonData.id,
        };
        checkMyTeam(temp).then((response) => {
          this.myTeamInfo = response.data.team;
          this.transactionTime =
            this.myTeamInfo.local_player_transaction_count_left / 2;
          this.leftValue = this.myTeamInfo.value_left;
        });
      }
    },
    getCurrentRoster() {
      let temp = {
        season_id: this.seasonData.id,
      };
      getCurrentRoster(temp).then((response) => {
        this.myTeamPlayerList = response.data;
        for (let index in this.myTeamPlayerList) {
          let temp = this.myTeamPlayerList[index];
          if (!temp.is_foreign_player) {
            this.myTeamLocalPlayerList.push(temp);
          }
        }
        this.myTeamLocalPlayerPage = 1;
        this.getMyTeamLocalPlayerPage(this.myTeamLocalPlayerPage);
        this.getSeasonDraftData(null, null);
      });
    },
    getSeasonDraftData(position, keyword) {
      let temp = {
        season_id: this.seasonData.id,
        country: "LOCAL",
        position: position,
        keyword: keyword,
      };
      getSeasonDraftData(temp).then((response) => {
        this.playerList = response.data;

        this.removePlayerList();
        this.playerPage = 1;
        this.getPlayersPage(this.playerPage);
      });
    },
    confirmTransaction() {
      if (
        this.myTeamLocalPlayerSelect.length == 0 ||
        this.playerListSelect.length == 0
      ) {
        return;
      }
      if (this.myTeamLocalPlayerSelect.length != this.playerListSelect.length) {
        this.showErrorDialog(
          "交易失敗",
          "我的球隊及球員列表的球員數量必須相同"
        );
        return;
      }
      if (
        !this.seasonData.is_transaction_unlimited &&
        this.myTeamLocalPlayerSelect.length > this.transactionTime
      ) {
        this.showErrorDialog("交易失敗", "剩餘交易次數不足");
        return;
      }

      let dropIDs = "";
      let addIDs = "";

      let tempMyTeamSelectValue = 0;
      let tempPlayerSelectValue = 0;

      for (let index in this.myTeamLocalPlayerSelect) {
        let temp = this.myTeamLocalPlayerSelect[index];
        tempMyTeamSelectValue = tempMyTeamSelectValue + temp.current_value;
        if (index == this.myTeamLocalPlayerSelect.length - 1) {
          dropIDs = dropIDs + temp.id;
        } else {
          dropIDs = dropIDs + temp.id + ",";
        }
      }

      for (let index in this.playerListSelect) {
        let temp = this.playerListSelect[index];
        tempPlayerSelectValue = tempPlayerSelectValue + temp.current_value;
        if (index == this.playerListSelect.length - 1) {
          addIDs = addIDs + temp.id;
        } else {
          addIDs = addIDs + temp.id + ",";
        }
      }

      if (this.myTeamInfo.total_value <= this.myTeamInfo.max_player_value) {
        if (this.leftValue < 0) {
          this.showErrorDialog("交易失敗", "剩餘價值不足");
          return;
        }
      } else {
        if (tempMyTeamSelectValue < tempPlayerSelectValue) {
          this.showErrorDialog(
            "交易失敗",
            "價值已超過上限，僅能更換丟棄球員價值總合以下的球員"
          );
          return;
        }
      }

      let temp = {
        season_id: this.seasonData.id,
        is_foreign_player: false,
        drop_ids: dropIDs,
        add_ids: addIDs,
      };
      createTransaction(temp)
        .then(() => {
          this.seasonData = {};
          this.transactionTime = 0;

          this.position = "ALL";
          this.nameText = "";

          this.playerPage = 1;
          this.playerList = [];
          this.tempPlayerList = [];
          this.currentPlayerList = [];
          this.playerListSelect = [];
          this.playerIDListSelect = [];

          this.myTeamLocalPlayerPage = 1;
          this.myTeamPlayerList = [];
          this.myTeamLocalPlayerList = [];
          this.currentMyTeamLocalPlayerList = [];
          this.myTeamLocalPlayerSelect = [];
          this.myTeamLocalPlayerIDSelect = [];
          this.getSeasonData();
          this.$emit("resetData");
          this.showSuccessDialog("交易成功", "交易已完成");
        })
        .catch(() => {
          this.closeLoadingDialog();
          this.showErrorDialog("交易失敗", "請連絡相關人員處理");
        });
    },
    getMyTeamLocalPlayerPage(page) {
      this.myTeamLocalPlayerPage = page;

      this.currentMyTeamLocalPlayerList = [];
      let number = 10 * this.myTeamLocalPlayerPage;
      this.currentMyTeamLocalPlayerList = this.myTeamLocalPlayerList.slice(
        number - 10,
        number
      );
    },
    getPlayersPage(page) {
      this.playerPage = page;

      this.currentPlayerList = [];
      let number = 10 * this.playerPage;
      this.currentPlayerList = this.tempPlayerList.slice(number - 10, number);
    },
    removePlayerList() {
      let tempList = Object.assign([], this.playerList);
      this.tempPlayerList = [];

      let selectedID = [];
      for (let i in this.myTeamLocalPlayerList) {
        let tempSelected = this.myTeamLocalPlayerList[i];
        selectedID.push(tempSelected.id);
      }
      for (let index in tempList) {
        let player = tempList[index];
        if (!selectedID.includes(player.id)) {
          this.tempPlayerList.push(player);
        }
      }
    },
    filterChange() {
      let tempPosition;
      let tempNameText;
      if (this.position == "ALL") {
        tempPosition = null;
      } else {
        tempPosition = this.position;
      }
      if (validStringEmpty(this.nameText)) {
        tempNameText = null;
      } else {
        tempNameText = this.nameText;
      }
      this.getSeasonDraftData(tempPosition, tempNameText);
    },
    myLocalPlayerClick(player) {
      if (this.myTeamLocalPlayerIDSelect.includes(player.id)) {
        this.myTeamLocalPlayerSelect = this.myTeamLocalPlayerSelect.filter(
          (data) => {
            if (data.id == player.id) {
              this.leftValue = this.leftValue - player.current_value;
            }
            return data.id != player.id;
          }
        );
        this.myTeamLocalPlayerIDSelect = this.myTeamLocalPlayerIDSelect.filter(
          (data) => {
            return data != player.id;
          }
        );
      } else {
        if (
          this.seasonData.is_transaction_unlimited ||
          this.myTeamLocalPlayerSelect.length < this.transactionTime
        ) {
          this.leftValue = this.leftValue + player.current_value;
          this.myTeamLocalPlayerSelect.push(player);
          this.myTeamLocalPlayerIDSelect.push(player.id);
        } else {
          this.showErrorDialog("失敗", "已超過可交換球員");
        }
      }
    },
    playerListClick(player) {
      if (this.playerIDListSelect.includes(player.id)) {
        this.playerListSelect = this.playerListSelect.filter((data) => {
          if (data.id == player.id) {
            this.leftValue = this.leftValue + player.current_value;
          }
          return data.id != player.id;
        });
        this.playerIDListSelect = this.playerIDListSelect.filter((data) => {
          return data != player.id;
        });
      } else {
        if (
          this.seasonData.is_transaction_unlimited ||
          this.playerListSelect.length < this.transactionTime
        ) {
          this.leftValue = this.leftValue - player.current_value;
          this.playerListSelect.push(player);
          this.playerIDListSelect.push(player.id);
        } else {
          this.showErrorDialog("失敗", "已超過可交換球員");
        }
      }
    },
    closeDialog() {
      this.$emit("closeTableLocalDialog");
    },
    onPlayerLeftArrowClick() {
      if (this.playerPage - 1 > 0) {
        this.getPlayersPage(this.playerPage - 1);
      }
    },
    onPlayerRightArrowClick() {
      if (this.playerPage + 1 <= Math.ceil(this.tempPlayerList.length / 10)) {
        this.getPlayersPage(this.playerPage + 1);
      }
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/fantasy/create_team";

tbody th {
  background: transparent;
  color: #515b65;
}

.btn_actions {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.arrow {
  display: inline-block;
  margin: auto;
  width: 50px;
  vertical-align: middle;
  flex-grow: 0;
}

.card {
  flex-grow: 1;

  .amount_container {
    width: 30%;
  }

  .amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    color: #515b65;
    margin-bottom: 0;
  }

  span.num {
    font-size: 20px;
    font-weight: bold;
    width: 50px;
  }

  .trans {
    color: #515b65;

    .title {
      color: #515b65;
      font-size: 13px !important;
    }
  }

  .current_player,
  .new_player {
    color: #c1c7ce;
    margin-right: 12px;
  }

  .current_player.selected {
    color: #515b65;
  }

  .new_player.selected {
    color: #c58330;
    font-weight: bold;
  }
}

@media (max-width: 959px) {
  .arrow {
    width: 30px;
  }
  .card {
    p,
    span.num {
      display: inline-block;
      font-size: 13px;
    }

    span.num {
      margin-left: 8px;
    }

    .trans {
      .title {
        font-size: 12px;
      }

      .current_player,
      .new_player {
        font-size: 13px;
        margin-right: 8px;
      }
    }
  }

  .btn_actions {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .btns_container {
    .btn_disable,
    .btn_water_blue,
    .btn_gray_hollow {
      flex-grow: 1;
    }
  }

  .card {
    .amount_container {
      width: 100%;
      line-height: 30px;
    }

    .amount {
      display: block;
      flex-grow: 1;
      justify-content: flex-start;
    }

    span.num {
      width: auto;
    }
  }
}

@media (max-width: 599px) {
  ::v-deep .v-dialog {
    margin: 0;
    max-height: 95%;
  }
}
</style>
